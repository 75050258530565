<template>
  <b-modal id="modal-gudang" size="lg" title="Detail Gudang">
    <div class="mb-2">
      <label>Nama Gudang</label>
      <b-col cols="12" class="custom__input mb-2 pt-1">
        {{ detailResult.name }}
      </b-col>
    </div>

    <div class="mb-2">
      <label>Alamat Gudang</label>
      <b-col cols="12" class="custom__input mb-2 pt-1">
        {{ detailResult.address }}
      </b-col>
    </div>

    <div class="mb-2">
      <label>Kota</label>
      <b-col cols="12" class="custom__input mb-2 pt-1">
        {{ detailResult.city }}
      </b-col>
    </div>
    <template #modal-footer="{}">
      <b-button variant="primary" @click="$bvModal.hide('modal-gudang')">
        Close
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { mapState } from 'vuex'
import {
  BModal, BContainer, BRow, BCol, BButton,
} from 'bootstrap-vue'

import moment from 'moment'
import BaseInput from '@/components/BaseInput.vue'

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BButton,
    BContainer,
    BaseInput,
  },
  props: {
    getData: {
      type: Function
    },
    result: {
      type: Object
    },
    getDetail: {
      type: Function
    },
    detailResult: {
      type: Object
    },
  },
  methods: {
    getPhoto(value) {
      this.formPayload.logo = value.photo
    },
    directPage() {
      //   this.cleanUpForm()
      this.$bvModal.hide('modal-gudang')
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/variables/_variables.scss';

label {
  font-size: 14px;
  font-weight: 600;
  color: #2B324F;
}

.custom__input {
  height: 52px;
  background: #EFF1F5;
  border: 1px solid #E4E5EC;
  border-radius: 8px;
  color: #2B324F;
  font-weight: 600;
  font-size: 14px;
}
</style>